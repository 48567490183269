(function ($, site) {
  Drupal.behaviors.countryChooser = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $menu = $('.country-select__menu .menu--lvl-1', context);
      var $links = $('.menu__link', $menu);
      var $select = $('select.country-select__selectbox', context);
      var $locale = $('.locale-select', context);
      var $options = [];
      var defaultCountry = typeof site.defaultCountry !== 'undefined' ? site.defaultCountry.toLowerCase() : '';

      $links.each(function () {
        var $me = $(this),
          href = $me.attr('href'),
          text = $me.text();
        var attr = '';

        if (text.toLowerCase() === defaultCountry) {
          attr += ' selected="selected"';
        }

        $options.push('<option value="' + href + '"' + attr + '>' + text + '</option>');
      });
      $select.append($options);
      $locale.find('img').attr('alt', defaultCountry);
      $select.addClass('selectBox');
      Drupal.behaviors.selectBox.attach($('.country-select'));
      // #iHateSelectBox for adding inline style width. Let's get rid of it.
      $('.country-select .selectBox-dropdown').css('width', '');

      $select.change(function () {
        var loc = $(this).val();

        if (loc) {
          window.location = loc;
        }
      });
      $('.language-select a').bind('click', function (event) {
        $.cookie('welcome15', null, { path: '/' });
      });
    },
    attached: false
  };
})(jQuery, window.site || {});
